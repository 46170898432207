{
    "titles": {
        "trending": "Trending",
        "login": "Login",
        "register": "Register",
        "feed": "Feed",
        "preferences": "Preferences",
        "history": "History",
        "subscriptions": "Subscriptions",
        "playlists": "Playlists"
    },
    "player": {
        "watch_on": "Watch on {0}"
    },
    "actions": {
        "subscribe": "Subscribe - {count}",
        "unsubscribe": "Unsubscribe - {count}",
        "view_subscriptions": "View Subscriptions",
        "sort_by": "Sort by:",
        "most_recent": "Most Recent",
        "least_recent": "Least Recent",
        "channel_name_asc": "Channel Name (A-Z)",
        "channel_name_desc": "Channel Name (Z-A)",
        "back": "Back",
        "uses_api_from": "Uses the API from ",
        "enable_sponsorblock": "Enable Sponsorblock",
        "skip_sponsors": "Skip Sponsors",
        "skip_intro": "Skip Intermission/Intro Animation",
        "skip_outro": "Skip Endcards/Credits",
        "skip_preview": "Skip Preview/Recap",
        "skip_interaction": "Skip Interaction Reminder (Subscribe)",
        "skip_self_promo": "Skip Unpaid/Self Promotion",
        "skip_non_music": "Skip Music: Non-Music Section",
        "skip_highlight": "Skip Highlight",
        "skip_filler_tangent": "Skip Filler Tangent",
        "show_markers": "Show Markers on Player",
        "theme": "Theme",
        "auto": "Auto",
        "dark": "Dark",
        "light": "Light",
        "autoplay_video": "Autoplay Video",
        "audio_only": "Audio Only",
        "default_quality": "Default Quality",
        "buffering_goal": "Buffering Goal (in seconds)",
        "country_selection": "Country Selection",
        "default_homepage": "Default Homepage",
        "show_comments": "Show Comments",
        "minimize_description_default": "Minimize Description by default",
        "store_watch_history": "Store Watch History",
        "language_selection": "Language Selection",
        "instances_list": "Instances List",
        "enabled_codecs": "Enabled Codecs (Multiple)",
        "instance_selection": "Instance Selection",
        "show_more": "Show More",
        "yes": "Yes",
        "no": "No",
        "export_to_json": "Export to JSON",
        "import_from_json": "Import from JSON/CSV",
        "loop_this_video": "Loop this Video",
        "auto_play_next_video": "Auto Play next Video",
        "donations": "Donations",
        "minimize_description": "Minimize Description",
        "show_description": "Show Description",
        "minimize_recommendations": "Minimize Recommendations",
        "show_recommendations": "Show Recommendations",
        "disable_lbry": "Disable LBRY for Streaming",
        "enable_lbry_proxy": "Enable Proxy for LBRY",
        "view_ssl_score": "View SSL Score",
        "search": "Search",
        "filter": "Filter",
        "loading": "Loading...",
        "clear_history": "Clear History",
        "show_replies": "Show Replies",
        "hide_replies": "Hide Replies",
        "load_more_replies": "Load more Replies",
        "add_to_playlist": "Add to playlist",
        "remove_from_playlist": "Remove from playlist",
        "delete_playlist_video_confirm": "Remove video from playlist?",
        "create_playlist": "Create Playlist",
        "delete_playlist": "Delete Playlist",
        "select_playlist": "Select a Playlist",
        "delete_playlist_confirm": "Delete this playlist?",
        "please_select_playlist": "Please select a playlist",
        "delete_account": "Delete Account",
        "logout": "Logout from this device",
        "minimize_recommendations_default": "Minimize Recommendations by default",
        "invalidate_session": "Logout all devices",
        "different_auth_instance": "Use a different instance for authentication",
        "instance_auth_selection": "Autentication Instance Selection",
        "clone_playlist": "Clone Playlist",
        "clone_playlist_success": "Successfully cloned!",
        "download_as_txt": "Download as .txt"
    },
    "comment": {
        "pinned_by": "Pinned by",
        "disabled": "Comments are disabled by the uploader.",
        "loading": "Loading comments...",
        "user_disabled": "Comments are disabled in the settings."
    },
    "preferences": {
        "instance_name": "Instance Name",
        "instance_locations": "Instance Locations",
        "has_cdn": "Has CDN?",
        "registered_users": "Registered Users",
        "version": "Version",
        "up_to_date": "Up to date?",
        "ssl_score": "SSL Score"
    },
    "login": {
        "username": "Username",
        "password": "Password"
    },
    "video": {
        "videos": "Videos",
        "views": "{views} views",
        "watched": "Watched",
        "sponsor_segments": "Sponsors Segments",
        "ratings_disabled": "Ratings Disabled",
        "chapters": "Chapters",
        "live": "{0} Live",
        "shorts": "Shorts"
    },
    "search": {
        "did_you_mean": "Did you mean: {0}?",
        "all": "YouTube: All",
        "videos": "YouTube: Videos",
        "channels": "YouTube: Channels",
        "playlists": "YouTube: Playlists",
        "music_songs": "YT Music: Songs",
        "music_videos": "YT Music: Videos",
        "music_albums": "YT Music: Albums",
        "music_playlists": "YT Music: Playlists"
    },
    "subscriptions": {
        "subscribed_channels_count": "Subscribed to: {0}"
    }
}
